.routecard-title {
    font-size: var(--text-size-s);
    line-height: 2rem;
    max-width: 18rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.routecard-top_group {
    column-gap: 0.8rem;
}
