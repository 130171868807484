@mixin alert-variant(
    $alert-background-color: var(--color-galaxy-200),
    $alert-color: var(--color-galaxy-800),
    $alert-border-color: transparent
) {
    background-color: $alert-background-color;
    color: $alert-color;
    @if $alert-border-color != transparent {
        border-color: $alert-border-color;
    }
}
