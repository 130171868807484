:root {
    // font sizes
    --text-size-xxxs: 0.8rem;
    --text-size-xxs: 1rem;
    --text-size-xs: 1.2rem;
    --text-size-s: 1.4rem;
    --text-size-m: 1.6rem;
    --text-size-l: 2.4rem;
    --text-size-xl: 3.2rem;
    --text-size-xxl: 3.6rem;
    --text-size-xxxl: 4.8rem;

    // icons
    --icon-xxxs: 0.6rem;
    --icon-xxs: 1rem;
    --icon-xs: 1.2rem;
    --icon-s: 1.6rem;
    --icon-m: 2rem;
    --icon-l: 2.4rem;
    --icon-xl: 3.6rem;
    --icon-xxl: 4.8rem;

    // border-radius
    --border-radius-round: 50%;
    --border-radius-xl: 1.6rem;
    --border-radius-l: 0.8rem;
    --border-radius-m: 0.4rem;
    --border-radius-s: 0.2rem;
}
