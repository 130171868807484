.shiftblock {
    margin-bottom: 1rem;
    border-radius: var(--border-radius-m);
    padding: 1rem;

    &.ishovered {
        margin-bottom: 1rem;
        background: var(--color-neptune-200);
    }
}

.shift-inputs {
    width: 26rem;
    padding: 0.6rem 1.2rem;
    border-radius: var(--border-radius-m);
    border: 0.1rem solid var(--color-galaxy-300);
    gap: 0.5rem;
    background: var(--color-white);
}

.shift-input {
    width: 8rem;
}

.shiftremovebutton {
    right: 2rem;
    top: -1rem;
}

.shiftslotremovebutton {
    right: 2rem;
    top: -1rem;
}

.shiftremovebutton,
.shiftslotremovebutton {
    &.hidden {
        visibility: hidden;
    }
    &.visible {
        visibility: visible;
    }
}

.shiftslots {
    gap: 2rem;
}

.shiftslot {
    border-radius: var(--border-radius-m);
    padding: 1rem 0;

    &.ishovered {
        background: var(--color-neptune-200);
    }

    &:nth-child(2) {
        padding: 1rem;
    }
}

.addnewslot {
    gap: 0.5rem;
    font-size: var(--text-size-s);
}
