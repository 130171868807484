.routecard-actionbutton {
    row-gap: 0.4rem;
}

.routecard-actionbutton_disabled {
    opacity: 0.5;
}

.routecard-actionbutton_icon {
    border-color: var(--color-ocean);
    border-radius: var(--border-radius-round);

    &:hover {
        background-color: var(--color-neptune-100);
    }

    &:disabled {
        &,
        &:hover {
            cursor: not-allowed;
            background-color: var(--color-comet);
        }
    }
}

.routecard-actionbutton_text {
    color: var(--color-meteor);
}
