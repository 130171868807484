@import 'src/ui/mixins/mapmarker';

.routemarker {
    @include mapmarker;
}

.routemarker-circle {
    @include mapmarker-circle(
        $circle-parent-name: routemarker,
        $circle-size: 4.8rem,
        $circle-hover-border-width: 0.4rem
    );
}

.routemarker-label {
    @include mapmarker-label($circle-parent-name: routemarker);
}
