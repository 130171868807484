.routecard-labels-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    column-gap: 0.8rem;
    row-gap: 0.4rem;

    li {
        font-size: var(--text-size-xs);
        line-height: 1.6rem;
        color: var(--color-galaxy-500);
        padding: 0.1rem 0.3rem;
        border: 1px solid var(--color-galaxy-300);
        border-radius: var(--border-radius-m);
        white-space: nowrap;
    }
}
