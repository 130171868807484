.managestopcontrol_modal {
    background-color: var(--color-gaxy-500);
}

.managestopcontrol_modal-content,
.managestopcontrol_modal-header {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
}

.managestopcontrol_modal-header {
    padding: 0 1.2rem 0.8rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);

    .tooltipbutton {
        column-gap: 0.8rem;
    }
}

.managestopcontrol_modal-content {
    padding-top: 1.2rem;
    row-gap: 1.2rem;

    > * {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}
