.editablepassword-container {
    position: relative;

    .showpassword-button {
        position: absolute;
        right: 0;
        top: -0.1em;

        &:hover {
            text-decoration: none;
        }
    }
}
