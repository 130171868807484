.search {
    position: relative;
    padding: 0.4rem 0.8rem;
    border-radius: 0.8rem;
}

.search_hover, .search_focus {
    background-color: var(--color-neptune-100);
}

.search-title {
    font-size: 1.2rem;
    display: block;
    color: var(--color-galaxy-500);
    margin-bottom: 0.4rem;
    text-transform: capitalize;
}

.search-input {
    font-size: 1.4rem;
    display: block;
    width: 100%;
    border: 0;
    background-color: transparent;
    padding-right: 2rem;
}

.search-input:focus {
    outline: none;
}

.search-clear {
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 0.4rem;
    transform: translateY(-50%);
}
