@import 'src/ui/mixins/mapmarker';

.driverlocationmarker {
    @include mapmarker;
    position: relative;
}

.driverlocationmarker-circle {
    @include mapmarker-circle(
        $circle-parent-name: driverlocationmarker,
        $circle-size: 2rem,
        $circle-border-width: 0,
        $circle-border-color: transparent,
        $circle-hover-border-width: 0
    );
}

.driverlocationmarker-icon {
    z-index: var(--z-index-map-controls-overlay);
}

.driverlocationmarker-aura {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: var(--z-index-map-controls-underlay);
    border-radius: var(--dimension-round);
    width: 4.8rem;
    height: 4.8rem;
    opacity: 0.3;
}
