.card {
    font-size: 1.4rem;
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: 0.8rem;
    margin-bottom: 0.8rem;
}

.card-body,
.card-footer {
    padding: 1.2rem 1.6rem;
}

.card-header {
    padding: 1.2rem 1.6rem 2rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);
}

.card-footer {
    border-top: 0.1rem solid var(--color-galaxy-200);
}

.card_muted {
    border: 0.1rem solid var(--color-galaxy-50);
    background-color: var(--color-galaxy-50);
}

.card-collapsiblebody {
    padding: 0 1.2rem;
    
    &.collapsed {
        display: none;
    }
}

.card-collapsibleheader {
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    background-color: unset;
    padding: 1.2rem;
}

.card-collapsibleheader_chevron {
    margin-left: auto;
    flex-shrink: 0;
    &[data-is-open="true"] {
        transform: rotate(180deg);
    }
}
