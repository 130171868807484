.inventoryitemstatus {
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%);
  align-items: center;
  color: var(--color-galaxy-500);
  border: 0.1rem solid;
  border-color: var(--color-galaxy-300);
  border-radius: var(--border-radius-m);
  padding: 0.2rem 0.4rem;
  font-size: var(--text-size-xs);
  font-weight: 400;
}

.inventoryitemstatus--unconfirmed {
  background-color: var(--color-galaxy-100);
}

.inventoryitemstatus--confirmed {
  background-color: var(--color-earth-500);
  color: var(--color-comet);
  border-color: transparent;
  column-gap: 0.5rem;
}

.inventoryitemstatus--cancelled {
  background-color: transparent;
  color: var(--color-galaxy-200);
  border-color: var(--color-galaxy-200);
}

.inventoryitemstatus--partial {
  background-color: var(--color-saturn-500);
  color: var(--color-comet);
  border-color: transparent;
  column-gap: 0.5rem;
}
