.map {
    position: relative;
}

.map_mode-default,
.map_mode-static {
    width: 100%;
}

.map_mode-simple {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}