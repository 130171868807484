.react-datepicker {
    font-family: Roboto;
    font-size: 1.4rem;
    border: 0.1rem solid white;
    border-radius: 0.4rem;
    box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
}

.react-datepicker__input-container {
    height: 100%;
    display: flex;
}

.react-datepicker__input-container input {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: 0.8rem;
    font-size: 1.6rem;
    padding: 1.2rem 1.6rem;
    width: 100%;
}

.react-datepicker__input-container input::placeholder {
    color: var(--color-galaxy-300);
}

.react-datepicker__input-container input:focus {
    outline: none;
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
}

.react-datepicker__header {
    background-color: white;
    padding-top: 0;
    border: none;
}

.react-datepicker__month {
    margin: 0.4rem 0.8rem 1rem 0.8rem;
}

.react-datepicker__day-name {
    width: 4rem;
    line-height: 4rem;
    font-weight: 500;
    margin: 0;
}

.react-datepicker__day {
    width: 4rem;
    line-height: 4rem;
    margin: 0;
}

.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
    background-color: var(--color-neptune-100);
    color: var(--color-ocean);
    border-radius: 0.8rem;
}

.react-datepicker__day--today {
    color: var(--color-meteor);
    font-weight: normal;
}

.react-datepicker__day--today:hover {
    color: var(--color-meteor);
}

.react-datepicker__day--selected {
    background-color: var(--color-ocean);
    color: white;
    border-radius: 0.8rem;
}

.react-datepicker__day--selected:hover {
    color: var(--color-ocean)
}

.react-datepicker__day--today.react-datepicker__day--selected:hover {
    color: var(--color-meteor);
}

.react-datepicker__day:focus {
    outline: none;
}
