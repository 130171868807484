.circleday-wrapper {
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

.circleday-content {
  align-items: center;
  justify-self: center;
  height: 4.4rem;
  width: 4.4rem;
  margin-top: 0.2rem;
  border-radius: 50%;

  span {
    flex: 1;
    text-align: center;
  }
}
