.radio--checked {
    display: none;

    input:checked ~ & {
        display: block;
    }
}

.radio--unchecked {
    display: block;
    input:checked ~ & {
        display: none;
    }
}

.radio-container {
    min-width: min-content;
    cursor: pointer;
}

.radio__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 1;
    height: 1;
    overflow: 'hidden';
}

.radio__icon {
    input:checked ~ & {
        color: 'primary';
    }

    input:focus ~ & {
        background-color: 'highlight';
    }

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-right: 0.8rem;
    border-radius: 9999;
    color: 'gray';
    flex-shrink: 0;
}
