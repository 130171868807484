@import 'src/ui/mixins/scrollbar';

.managestopcontrol_multiple {
    row-gap: 1.2rem;
}

.managestopcontrol_stop-list {
    max-height: 8.8rem;
    overflow-y: auto;

    /* for Firefox */
    min-height: 0;

    @include thin-scrollbar;

    * + & {
        margin-top: 1.2rem;
    }
}
