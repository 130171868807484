.tray {
    width: 24rem;
    border-radius: 0.8rem;
    background-color: var(--color-comet);
    box-shadow: 0 0.8rem 1.6rem 0 var(--color-box-shadow);
}

.tray-header {
    padding: 1rem;
    color: var(--color-galaxy-800);
    font-size: 1.4rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);
}

.tray-subtitle {
    color: var(--color-galaxy-500);
}

.tray-body {
    padding: 1rem;
}
