.taskdetaildrawer {
    position: fixed;
    right : 0;
    top: var(--dimension-bar-offset-height);
    box-shadow: 0 1.5rem 3.5rem var(--color-box-shadow);
    background-color: var(--color-neptune-100);
    z-index: var(--z-index-drawers);
    overflow: auto;

    &.drawer {
        width: 36rem;
        height: calc(100vh - var(--dimension-bar-offset-height));
    }
}

.taskdetaildrawer-mappage {
    &.taskdetaildrawer {
        position: absolute;
        top: 0;
    }
}

.taskdetaildrawer-header {
    background-color: var(--color-comet);
    padding: 1rem 1.6rem 0rem 1.6rem;
    min-height: 0;
}

.taskdetaildrawer-header_sticky {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-header);
    background-color: var(--color-comet);
}

.taskdetaildrawer-status {
    display: grid;
    width: 9rem;
    grid-template-columns: 2.5fr;
    column-gap: 0.5rem;
}

.taskdetaildrawer-map {
    height: 13.7rem;
    border-radius: var(--border-radius-l);
    overflow:hidden;
}

.taskdetaildrawer-tabs {
    background-color: var(--color-comet);
    padding: 0 1.6rem 1rem 1.6rem;
}
