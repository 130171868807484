.weekday-wrapper {
    gap: 0.5rem;
}

.weekday-block {
    font-size: var(--text-size-xs);
    border-radius: var(--border-radius-m);
    background: var(--color-white);
    border: 0.1rem solid var(--color-neptune-200);
    color: var(--color-ocean);

    &.small {
        width: 2.4rem;
        height: 2.4rem;
    }

    &.large {
        width: 4.8rem;
        height: 4.8rem;
    }
}

.weekday-block.inactive {
    color: var(--color-galaxy-400);
    background: var(--color-galaxy-100);
    border: 0.1rem solid transparent;
}

.weekday-checkbox {
    display: none;
}

.weekday-checkbox-label {
    cursor: pointer;
}
