@import 'src/ui/mixins/scrollbar';

@mixin stopcontrol-childmodal-header() {
  display: grid;
  width: 100%;
  padding: 0 0 0.8rem;
  border-bottom: 0.1rem solid var(--color-galaxy-200);
  grid-template-columns: 2rem 1fr;
  grid-gap: 0.8rem;

  .icon {
    transform: rotate(90deg);
    cursor: pointer;
  }
}

@mixin stopcontrol-childmodal-content() {
  padding: 0;
  width: 100%;

  .stop-list {
    max-height: 11.1rem;
    overflow-y: auto;
    margin-top: 0.8rem;

    /* for Firefox */
    min-height: 0;

    @include thin-scrollbar;
  }
}

@mixin stopcontol-childmodal-content-search() {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.2rem;
  padding: 1.2rem;
  margin-top: 1rem;
  background-color: var(--color-comet);
  border-radius: var(--border-radius-l);

  input {
    padding: 0.8rem;
  }

  > span {
    max-width: 17.6rem;
  }
}

@mixin stopcontrol-childmodal-search-dropwdown() {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  min-height: 5rem;
  max-height: 14.5rem;
  background-color: var(--color-comet);
  box-shadow: 0 0.2rem 0.6rem rgba(24, 41, 65, 0.15);
  border-radius: var(--border-radius-l);
  padding: 1.2rem;
  overflow-y: scroll;

  /* for Firefox */
  min-height: 0;

  @include thin-scrollbar;

  button {
    display: flex;
    background-color: var(--color-comet);
    text-align: left;
    border: none;
    border-radius: 0;
    width: 100%;
    padding: 1rem 0;

    &:hover {
      background-color: var(--color-neptune-100);
    }
  }
}

@mixin stopcontrol-childmodal-search() {
  position: relative;

  > div:first-of-type {
    width: 100%;

    input {
      width: 100%;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}

@mixin stopcontrol-childmodal-content-button() {
  width: 100%;
  padding: 0.8rem;

  .icon {
    margin-right: 0.9rem;
    margin-top: -0.1rem;
    min-height: 1rem;
    max-height: 1rem;
  }
}
