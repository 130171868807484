.planmapoverlay {
    column-gap: 1.6rem;
    row-gap: 1.6rem;
    z-index: var(--z-index-map-controls);
}

.planmapoverlay_mapcontrols {
    bottom: 2rem;
    right: 2.4rem;
}

.planmapoverlay_stopcontrols {
    right: 2.4rem;
    top: 1.2rem;
}
