.managestopcontrol_single {
    row-gap: 1.2rem;

    .routecard-metric {
        border-radius: var(--border-radius-l);
        color: var(--color-mars-400);
        background-color: var(--color-mars-100);
        text-transform: uppercase;
        width: 7.5rem;
        font-size: var(--text-size-xxs);
        line-height: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.6rem;
    }

    .routecard-address {
        margin: 0.8rem 0 0.2rem 0;
        & button {
            // meant to keep the icon aligned to the first line
            // in-cases where the stop address cannot fit 1 line
            align-items: unset;
        }
        & span {
            align-self: normal;
        }
    }
    .routecard-eta {
        margin-bottom: 0.4rem;
    }
    .routecard-text {
        // helps with icon alignment for address and service window
        position: relative;
        right: 0.9rem;
    }
    .routecard-labels {
        margin-bottom: 1rem;
    }
}
