.zonemanagement {
  width: 100vw;
  height: calc(100vh - var(--dimension-navbar-height));
  padding: 6rem 12rem;

  > div {
    width: 100%;
    max-width: 144rem;
  }

  #map div[style*='crosshair'] {
    cursor: url('~/ui/assets/images/map-pen-meteor.svg') 0 20, crosshair !important;
  }
}

.zonemanagement-body {
  height: 100%;
  margin-top: 3.2rem;
}

.zonemanagement-header {
  gap: 0.5rem;
}

.zonemanagement-header-title {
  font-weight: 500;
  font-size: var(--text-size-l);
  line-height: 2.8rem;
}

.zonemanagement-zonelist {
  width: 30rem;
  background: var(--color-comet);
  border-radius: var(--border-radius-l);
  border-right: 0.05rem solid var(--color-galaxy-200);
}

.zonemanagement-zonelist-header {
  gap: 0.8rem;
  border-bottom: 0.1rem solid var(--color-galaxy-200);
  padding: 2.3rem 1.5rem 1.35rem;
}

.zonemanagement-zonelist-nozone {
  padding: 4.14rem 3.2rem;
}

.zonemanagement-zonelist-item {
  margin: 0 0.9rem;
  min-height: 4.7rem;
  gap: 1rem;

  .zonelistitem-buttongroup {
    display: none;
  }

  &:hover {
    background-color: var(--color-neptune-200);
    border-radius: var(--border-radius-l);
  }

  &:hover .zonelistitem-buttongroup {
    display: flex;
    margin-left: auto;
  }

  .icon svg {
    fill: var(--color-galaxy-800);
  }
  > .icon {
    transform: rotate(-90deg);
  }
}

.zonemanagement-zonelist-item-name {
  color: var(--color-galaxy-800);
  text-transform: capitalize;
}

.zonemanagement-zonelist-item-vehicles {
  > span {
    font-weight: 500;
    font-size: var(--text-size-xs);
    line-height: 2rem;
    color: var(--color-galaxy-800);
  }
}

.zonemanagement-mapsection {
  width: 114rem;
  border-radius: var(--border-radius-l);
  background: var(--color-comet);
  padding: 2.5rem 2.4rem;
  border-left: 0.05rem solid var(--color-galaxy-200);
}

.zonemanagement-stats {
  gap: 2.8rem;
}

.zonemanagement-stats-stat {
  color: var(--color-galaxy-500);

  > span {
    color: var(--color-galaxy-800);
  }
}

.zonemanagement-mapsection-map {
  height: 100%;

  #map {
    z-index: var(--z-index-map);
    height: 100%;
  }
}

.zonemanagement-addzone {
  z-index: var(--z-index-map-controls);
  top: 1rem;
  left: 2rem;
  width: 20rem;

  > .dropdown {
    width: 100%;
  }
}

.zonemanagement-addzone .dropdown-item {
  font-size: var(--text-size-m);
  line-height: 1.6rem;
  color: var(--color-galaxy-800);
}

.zonemanagement-addzone > .dropdown > .dropdown-show-items {
  top: 3.6rem;
  right: 0;
  background: var(--color-comet);
  box-shadow: 0 0.2rem 0.6rem rgba(24, 41, 65, 0.15);
  border-radius: var(--border-radius-l);
}

.zonemanagement-addzone-dropdown {
  gap: 0.8rem;
  padding: 1.2rem 2rem;
  height: 4.2rem;
  background: var(--color-comet);
  box-shadow: 0 0.2rem 0.6rem rgba(24, 41, 65, 0.15);
  border-radius: var(--border-radius-l);
  cursor: pointer;
}

.zonemanagement-addzone-dropdown-title {
  font-weight: 500;
  font-size: var(--text-size-m);
  line-height: 2.4rem;
  text-align: center;
  color: var(--color-ocean);

  + div {
    padding-top: 0.2rem;

    svg {
      fill: var(--color-ocean);
    }
  }
}

.zonemanagement-mapsection-overlay {
  &-maptypecontrol, &-zoomcontrol {
    z-index: var(--z-index-map-controls);
    bottom: 2.9rem;
  }

  &-maptypecontrol {
    left: 2.7rem;
  }

  &-zoomcontrol {
    right: 2.15rem;

    > .zoom-column {
      width: 4.445rem;
      height: 8.89rem;
      display: grid;
      grid-auto-columns: 1fr 2.222rem 1fr;
    }

    & .zoom-column-btn-wrapper {
      display: grid;
      justify-content: center;
    }
  }
}
