@import 'src/ui/mixins/stopscontrol-childmodal';

.reassignmodal-header {
  @include stopcontrol-childmodal-header;
}

.reassignmodal-content {
  @include stopcontrol-childmodal-content;
}

.reassignmodal-search {
  @include stopcontrol-childmodal-search;
}

.reassignmodal-content-search {
  @include stopcontol-childmodal-content-search;
  margin-bottom: 0.4rem;

  > div:first-of-type {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.2rem 1fr;
    grid-auto-rows: 2.4rem;
    grid-gap: 1.4rem;
  }
}

.reassignmodal-search-dropwdown {
  @include stopcontrol-childmodal-search-dropwdown;
}

.reassignmodal-content-button {
  @include stopcontrol-childmodal-content-button;

  .icon {
    max-height: 1.4rem;
  }
}
