.modal-content {
    margin: 2.4rem;
    color: var(--color-galaxy-800);
    font-size: var(--text-size-m);
    font-weight: 400;
}

.modal-content-delete {
    display: flex;
    flex-direction: column;
    font-weight: 400;
}

.usergroup-modal-footer {
    align-items: center;
    margin: 2.4rem;
    font-size: var(--text-size-m);
    color: var(--color-meteor);
}

.nameinput-error {
    display: flex;
    align-items: center;
    padding: 1.2rem 1.8rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background-color: var(--color-mars-100);
    font-weight: 400;
}
