@mixin mapmarker() {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }
}

@mixin mapmarker-circle(
    $circle-parent-name: mapmarker,
    $circle-size: 2.4rem,
    $circle-border-width: 0.1rem,
    $circle-border-style: solid,
    $circle-border-color: var(--color-comet),
    $circle-hover-border-width: 0.2rem
) {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--dimension-round);
    border-width: $circle-border-width;
    border-style: $circle-border-style;
    border-color: $circle-border-color;
    width: $circle-size;
    height: $circle-size;
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);

    .#{$circle-parent-name}:hover &,
    .#{$circle-parent-name}_selected & {
        border-width: $circle-hover-border-width;
    }
}

@mixin mapmarker-label($circle-parent-name: mapmarker) {
    font-weight: 500;
    background-color: var(--color-comet);
    border-radius: var(--dimension-round);
    padding: 0.2rem 1.6rem;
    max-width: 9.6rem;
    white-space: nowrap;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);

    .#{$circle-parent-name}_selected & {
        color: var(--color-comet);
        background-color: var(--color-black);
    }

    .#{$circle-parent-name}-circle + &,
    .#{$circle-parent-name}_icon-group + &,
    .#{$circle-parent-name}-body + & {
        margin-top: 0.4rem;
    }
}

@mixin mapmarker-star(
    $icon-size: 2.8rem,
    $stroke-color: var(--color-comet),
    $stroke-width: 0.1rem,
    $hover-stroke-width: 0.2rem
) {
    .icon {
        width: $icon-size;
        height: $icon-size;
    }

    svg {
        stroke: $stroke-color;
        stroke-width: $stroke-width;
    }

    &:hover svg {
        stroke-width: $hover-stroke-width;
    }
}

@mixin mapmarker-star-number(
    $font-size: var(--text-size-xxs),
    $text-x-translate: 0,
    $text-y-translate: -200%,
    $text-color: var(--color-comet),
    $font-weight: 500
) {
    transform: translate($text-x-translate, $text-y-translate);
    font-size: $font-size;
    font-weight: $font-weight;
    color: $text-color;
}
