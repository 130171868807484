.table {
  flex-grow: 1;
  border-collapse: collapse;
  & tr {
    border: solid var(--color-galaxy-100);
    border-width: 0.1rem 0;
  }
}

.table-header {
  background-color: var(--color-saturn-100);
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}

.table-header_sticky {
  position: sticky;
  z-index: var(--z-index-sticky-header);
}

.table-header-cell {
  background-color: var(--color-saturn-100);
  height: 4.8rem;
  padding: 0 0.5rem;

  &:first-of-type { padding-left: 3.3rem; }
}

.table-column-header {
  color: var(--color-galaxy-500);

  &_sorted {
    color: var(--color-neptune-800);
  }
}

.table-footer {
  flex: 1;
}
