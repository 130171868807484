$status-colors: galaxy-500, ocean, meteor, earth, saturn-500;

@mixin status-color-modifier($property) {
  @each $color in $status-colors {
    &--#{$color} {
      #{$property}: var(--color-#{$color});
    }
  }
}

.taskstatus-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
  border-radius: var(--border-radius-round);
  @include status-color-modifier('background-color');
}

.taskstatus-text {
  font-weight: 500;
  margin-left: 0.8rem;
  font-size: 1.4rem;
  @include status-color-modifier('color');
}
