.taskmapmarker {
    height: 2rem;
    width: 2rem;
    border-radius: var(--border-radius-round);
}

.taskmapmarker_selected {
    background-color: var(--color-ocean);
    border: 0.2rem solid var(--color-comet);
}

.taskmapmarker_unselected {
    background-color: var(--color-comet);
    border: 0.2rem solid var(--color-galaxy-500);
}
