.circle {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 60%;
  float: left;
}

.circle_galaxy-300 {
  background-color: var(--color-galaxy-300);
}

.circle_earth {
  background-color: var(--color-earth);
}

.circle_mars {
  background-color: var(--color-mars);
}

.circle_saturn {
  background-color: var(--color-saturn);
}

.passwordinput-reminder-text {
  display: flex;
  font-size: var(--text-size-xs);
  font-weight: normal;
  color: var(--color-galaxy-500);

  &.valid {
    color: var(--color-galaxy-800);
  }
}
