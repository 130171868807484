.proofofdelivery-item {
  align-items: flex-start;
  background-color: var(--color-comet);
  border: 0.1rem solid var(--color-galaxy-200);
  border-radius: var(--border-radius-l);
  padding: 1rem;
}

.proofofdelivery-item_received:hover {
    background-color: var(--color-neptune-100);
}

.proofofdelivery_notreceived {
  background-color: var(--color-galaxy-100);
  border: 0.1rem solid var(--color-galaxy-300);
  border-radius: var(--border-radius-m);
  padding: 0.2rem 0.4rem;
}
