@import 'src/ui/mixins/scrollbar';

.multi-tasks-control_list-item {
    .icon-button {
        background-color: var(--color-galaxy-500);
    }
}

.dispatch-tasks-control_error {
    .managestopcontrol_modal-content > & {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
}

.dispatch-tasks-control_list {
    max-height: 32rem;
    overflow-y: auto;

    /* for Firefox */
    min-height: 0;

    @include thin-scrollbar;
}

.dispatch-tasks-control_view-all {
    color: var(--color-meteor);
}

.dispatch-tasks-control_footer {
    padding-top: 1.2rem;
    border-top: 0.1rem solid var(--color-galaxy-200);
}

.suggested-driver {
    width: 21.6rem;
}

.pair-tasks-control {
    .managestopcontrol_modal-content {
        margin-left: 0;
        margin-right: 0;

        & > * {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.pair-tasks-control_text {
    margin-top: 0.4rem;
}

.pair-tasks-control_error {
    .managestopcontrol_modal-content > & {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}
