.field {
    display: flex;
    flex-direction: column;
}

.field-title {
    font-size: 1.2rem;
    color: var(--color-galaxy-500);
    margin-bottom: 0.4rem;
}

.field-title_clickable {
    color: var(--color-meteor);
    border: none;
    background: none;
    cursor: pointer;
}
