.loading_bar {
    height: 0.8rem;
    border-radius: var(--border-radius-m);
    overflow-x: hidden;
    background-color: var(--color-galaxy-100);
}

.loading_indicator {
    position: relative;
    height: 100%;
    width: 25%;
    border-radius: var(--border-radius-m);
    background-color: var(--color-neptune);
    @keyframes loading {
        from {
            left: -15%;
        }
        to {
            left: 90%;
        }
    }
    animation-name: loading;
    animation-duration: 1.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.loading {
    height: 100vh;
    background-color: var(--color-galaxy-100);
    z-index: var(--z-index-loading);

    &[data-offset='0'] {
        padding-left: var(--dimension-closed-drawer-width);
    }

    &[data-offset='1'] {
        padding-left: var(--dimension-drawer-width);
    }

    &[data-offset='2'] {
        padding-left: calc(var(--dimension-drawer-width) * 2);
    }

    .loading_bar {
        max-width: 24rem;
        width: 80%;
        background-color: var(--color-comet);
    }
}

.loading_voffset {
    padding-top: var(--dimension-bar-offset-height);
}

.loading_fillviewport {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
