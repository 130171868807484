.dateselectmodal-container {
  flex-direction: column;
  height: 100%;
}

.dateselectmodal-header {
  padding: 0.8rem 3rem;
  background-color: var(--color-comet);
  border-top-left-radius: var(--border-radius-l);
  border-top-right-radius: var(--border-radius-l);

  .week-list {
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 3rem;

    .previousweek {
      margin-right: 3rem;
      transform: rotate(90deg);
    }

    .nextweek {
      margin-left: 3rem;
      transform: rotate(-90deg);
    }
  }
}

.dateselectmodal-content {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  overflow-y: auto;

  .station-wrapper {
    position: relative;
    width: 22rem;
    height: 20rem;
    border: 0.1rem solid var(--color-ocean);
    border-radius: 0.8rem;
    margin: 2.4rem;
    background-color: var(--color-comet);

    .name {
      padding: 1.6rem 4rem 1.6rem 1.6rem;
      color: var(--color-galaxy-800);
    }

    .selection-icon {
      position: absolute;
      right: 1.6rem;
      top: 1.2rem;
    }
  }
}

.dateselectmodal-footer {
  justify-content: flex-end;

  .action-btn {
    margin: 1.6rem 2.4rem 1.6rem 0;
  }
}
