.fuzzytextsearch {
  position: relative;
  width: 71.3rem;
}

.fuzzytextsearch-input {
  height: 6rem;
  width: inherit;
  padding: 0 2rem 0 2rem;
  border: 0.1rem solid var(--color-galaxy-200);
  border-radius: var(--border-radius-l);
  grid-template-columns: 2rem 1fr 2rem;
  column-gap: 1.6rem;
  background-color: var(--color-comet);

  & input {
    border: none;
    outline: none;
    font-size: var(--text-size-m);
    color: var(--color-galaxy-800);
  }
}

.fuzzytextsearch-results {
  position: absolute;
  top: 7rem;
  max-height: 35rem;
  overflow: auto;
  width: 100%;
  background-color: var(--color-comet);
  border-radius: var(--border-radius-l);
  box-shadow:  0 0.8rem 1.6rem 0 var(--color-box-shadow);
}

.fuzzytextsearch_focus {
  border: 0.1rem solid var(--color-ocean);
  background-color: var(--color-neptune-100);

  & input {
    background-color: var(--color-neptune-100);
  }
}


