.buttongroup {
    width: 100%;
}

.buttongroup_button {
    padding: 0.5rem 0.8rem;
    color: var(--color-galaxy-800);
    background-color: transparent;
    box-shadow: 0rem 0rem 0rem transparent;
    border-color: var(--color-galaxy-200);
    border-style: solid;
    border-width: 0.1rem;
    font-size: var(--text-size-s);
    line-height: 2rem;
    cursor: pointer;
    width: 100%;

    & + .buttongroup_button {
        border-left-width: 0;
    }
}

.buttongroup_button:hover {
    background-color: var(--color-galaxy-50);
}

.buttongroup_button:first-child {
    border-top-left-radius: var(--border-radius-l);
    border-bottom-left-radius: var(--border-radius-l);
}

.buttongroup_button:last-child {
    border-top-right-radius: var(--border-radius-l);
    border-bottom-right-radius: var(--border-radius-l);
}

.buttongroup_button[disabled] {
    color: var(--color-galaxy-300);
    cursor: no-drop;
}

.buttongroup_button[data-selected] {
    color: var(--color-meteor);
    background-color: var(--color-comet);
    border-color: var(--color-ocean);
    cursor: default;
    margin-left: -0.1rem;
    border-left-width: 0.1rem;
}
