.drivermarker-popup {
  width: 20rem;
  position: absolute;
  bottom: 100%;
  padding: 1.4rem;
  background-color: var(--color-comet);
  opacity: 0.9;
  backdrop-filter: blur(10rem);
  border-radius: 0.8rem;
}

.drivermarker-popup_exception-text {
  padding: 0.4rem 0.4rem 0 0.4rem;
}
