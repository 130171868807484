.skin_unplanned {
    background-color: var(--color-galaxy-400);
    background-image:
            linear-gradient(
                45deg,
                var(--color-galaxy-500) 25%,
                transparent 25%,
                transparent 75%,
                var(--color-galaxy-500) 75%,
                var(--color-galaxy-500)
            ),
            linear-gradient(
                    45deg,
                    var(--color-galaxy-500) 25%,
                    transparent 25%,
                    transparent 75%,
                    var(--color-galaxy-500) 75%,
                    var(--color-galaxy-500)
            );
    background-size: 1.2rem 1.2rem;
    background-position: 0 0, 0.6rem 0.6rem;
    color: var(--color-comet);
}
