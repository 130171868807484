.signaturemodal {
  border: 0.1rem solid var(--color-galaxy-300);
  border-radius: var(--border-radius-l);
  margin: 2.4rem;
  width: 61.2rem;
  height: 24rem;
}

.signaturemodal-image {
  max-width: 100%;
  max-height: 100%;
}
