.multiselect_selector {
  padding: 0 1.2rem;
  background-color: var(--color-comet);
  border: 0.1rem solid var(--color-galaxy-200);
  border-radius: var(--border-radius-l);
  cursor: pointer;
}

.multiselect_placeholder {
  color: var(--color-galaxy-400);
  padding: 0.5em 0;
}

.multiselect_selected {
  flex: 1;
}

.multiselect_selected-item {
  color: var(--color-meteor);
  background-color: var(--color-neptune-100);
  padding: 0.3rem 0.8rem;
  margin: 0.3rem 0;
  border: 0.1rem solid var(--color-ocean);
  border-radius: var(--border-radius-l);
}

.multiselect_option-wrapper {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--color-comet);
  border-radius: var(--border-radius-l);
  box-shadow: 0rem 0.5rem 1.5rem var(--color-box-shadow);
  z-index: var(--z-index-action);
}

.multiselect-option-item {
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}

.multiselect-option-subitem {
  padding-left: 3.6rem;
}

.multiselect-option-item_text {
  color: var(--color-galaxy-500);
}
