.zone-management-list-item-vehicle-container {
    cursor: pointer;
    gap: 1.5rem;
    color: var(--color-black);
    padding: 1rem 1rem 1rem 2rem;
    border-radius: var(--border-radius-l);
    border: 0.1rem solid var(--color-galaxy-200);
}

.associatedvehicle-info {
    gap: 0.5rem;
    margin-right: auto;
}

.associatedvehicle-name {
    font-size: var(--text-size-s);
}

.associatedvehicle-details {
    gap: 1rem;
    font-size: var(--text-size-xs);
}

.associatedvehicle-detail {
    gap: 0.3rem;
}
