.detaildrawer-item {
  background-color: var(--color-comet);
  padding: 0 1.6rem 1rem 1.6rem;

  & + & {
      padding-top: 1rem;
  }
}

.detaildrawer-item-body {
  height: 0;
  transition: height 300ms, padding-top 300ms;
  overflow: hidden;
  margin-top: 0.5rem;
}

.detaildrawer-item-body .detail-content {
  font-size: var(--text-size-s);
  width: 100%;
  word-break: break-all;
  text-align: justify;
}

.detaildrawer-item-icon {
  min-width: var(--icon-s);
  min-height: var(--icon-s);
}

.detaildrawer-item_open .detaildrawer-item-body {
  height: fit-content;
  overflow: visible;
}

.detaildrawer-item-button {
  background: none;
  width: 100%;
  text-align: start;
  border: solid 0.1rem transparent;
  border-radius: var(--border-radius-l);
  padding: 0.6rem 0.8rem;
  position: relative;
  bottom: 0.7rem;
  min-height: 3rem;
}

.detaildrawer-item-button:hover {
  border-color: var(--color-galaxy-300);
  background-color: var(--color-galaxy-50);
  cursor: pointer;
}

.detaildrawer-item-button_disabled:hover {
  border-color: transparent;
  background-color: transparent;
  cursor: default;
}

.detaildrawer-item-divider {
  height: 1rem;
  background-color: var(--color-galaxy-50);
}
