.undobutton {
  border-radius: 0.8rem;
  width: 5rem;
  height: 2.7rem;
  margin-left: 0.9rem;
  cursor: pointer;

  &:hover {
    background: #455265;
  }

  > button {
    cursor: pointer;
    color: var(--color-sky);
    background: none;
    border: none;
  }
}
