._w-40 {
    width: 40%;
}

._w-50 {
    width: 50%;
}

._w-60 {
    width: 60%;
}

._w-91 {
    width: 91.11%;
}
._w-100 {
    width: 100%;
}
