$rotate: 90, 180, 270;

@each $item in $rotate {
    ._t-rotate-#{$item} {
        transform: rotate(#{$item}deg);
    }
    ._t-rotate-neg#{$item} {
        transform: rotate(-#{$item}deg);
    }
}
