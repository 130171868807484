.icon-wrapper {
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: transparent;
    border-radius: 0.8rem;
}

.icon-wrapper:hover {
    background-color: var(--color-galaxy-50);
}

.icon-wrapper--dark:hover {
    background-color: var(--color-galaxy-100);
}
