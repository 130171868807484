.accordion {
    padding: 1.2rem;
    border-bottom: 0.1rem solid var(--color-galaxy-200);
}

.accordion:first-of-type {
    border-top: 0.1rem solid var(--color-galaxy-200);
}

.accordion-top {
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-galaxy-500);
}

.accordion-bottom {
    height: 0;
    transition: height 300ms, padding-top 300ms;
    overflow: hidden;
}

.accordion_open .accordion-bottom {
    height: 41rem;
    padding-top: 1.2rem;
}

.accordion_hover {
    background-color: var(--color-galaxy-50);
}

.accordion_hover .accordion-top,
.accordion_open .accordion-top {
    color: var(--color-galaxy-800);
}

.accordion_disabled .accordion-button:hover {
    cursor: not-allowed;
}

.accordion-button {
    width: auto;
    height: auto;
    border: none;
}
