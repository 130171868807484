.baseactionbar {
    background-color: var(--color-comet);
    z-index: var(--z-index-action);
    width: 100%;
    box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
    height: var(--dimension-action-bar-height);
    padding-left: 2rem;

    /**
     * _p-relative class ensures the action bar still appears in front of the map overlay with its
     * higher z-index.  Previously it did this by nature of having `fixed` positioning
     */
}

.baseactionbar_buttongroup {
    column-gap: 1rem;
}
