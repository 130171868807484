.tabletoolbar {
  background-color: var(--color-comet);
  height: var(--dimension-table-toolbar-height);
}

.tabletoolbar_sticky {
  position: sticky;
  z-index: var(--z-index-drawers);
}

.tabletoolbar-button_search {
  background-color: var(--color-comet);
  padding: 0.8rem 2rem;
  border: 0.1rem solid var(--color-galaxy-200);
  border-radius: var(--border-radius-l);

  &:hover {
    background-color: var(--color-neptune-100);
    border: 0.1rem solid var(--color-ocean);
  }
}

.tabletoolbar-filter {
  border: 0.1rem solid var(--color-galaxy-200);
  border-radius: var(--border-radius-l);

  &:hover {
    background-color: var(--color-neptune-100);
    border: 0.1rem solid var(--color-ocean);
  }
}

.tabletoolbar-searchbar {
  height: 4.2rem;
  border-radius: var(--border-radius-l);
  border: 0.1rem solid var(--color-galaxy-200);

  &_focused {
    border-color: var(--color-ocean);
  }
}

.tabletoolbar-searchinput {
  flex-grow: 1;

  & input {
    border: none;
    outline: none;
    border-radius: var(--border-radius-l);
  }
}

.tabletoolbar-searchbar-filter {
  border-left: 0.1rem solid var(--color-galaxy-200);

  &:hover {
    background-color: var(--color-neptune-100);
  }
}

.tabletoolbar-button_clear {
  background-color: var(--color-comet);
  padding: 0 1.6rem;
  color: var(--color-meteor);
  font-size: 1.6rem;
  border: none;

  &:hover {
    color: var(--color-neptune-500);
  }
}

.tabletoolbar-button_close {
  background-color: var(--color-ocean);
  border: 0.1rem solid var(--color-ocean);
  border-radius: 0 0.7rem 0.7rem 0;
  width: 4.2rem;
  align-self: stretch;
}
