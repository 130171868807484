.toggle {
    font-size: 1.6rem;
    display: inline-flex;
}

.toggle:hover {
    cursor: pointer;
}

.toggle-input {
    display: none;
}

.toggle-circle {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 2.5em;
    height: 1.5em;
    transition: all 200ms;
}

.toggle-circle::before {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 1.5em;
    height: 1.5em;
    transition: all 200ms;
}

.toggle-input:checked + .toggle-circle::before {
    left: 100%;
    transform: translate(-100%, 0%);
}

.toggle_primary .toggle-circle {
    background-color: var(--color-galaxy-200);
    border-radius: 200em;
}

.toggle_primary .toggle-circle::before {
    border: 0.0625em solid var(--color-galaxy-200);
    border-radius: inherit;
    background-color: var(--color-comet);
}

.toggle_primary .toggle-input:checked + .toggle-circle {
    background-color: var(--color-ocean);
}

.toggle_primary .toggle-input:checked + .toggle-circle::before {
    border-color: var(--color-ocean);
}

.toggle-circle-disabled {
    cursor: not-allowed;
}

.toggle_primary .toggle-circle-disabled {
    opacity: 0.65;
}

.toggle_primary .toggle-input:checked + .toggle-circle-disabled {
    opacity: 0.3;
}