.detail {
    font-size: 1rem;
    color: var(--color-galaxy-800);
    margin-bottom: 0.8em;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.detail:last-of-type {
    margin-bottom: 0;
}

.detail-content {}

.detail-main {
    margin-bottom: 0.2em;
}

.detail-secondary {
    color: var(--color-galaxy-500);
}

.detail-icon {
    margin-right: 0.8em;
}
