.depot-marker {
    transform: translate(-50%, -50%);
    background-color: var(--color-depot-icon);
    height: var(--icon-xl);
    width: var(--icon-xl);
    border: 0.4rem solid var(--color-comet);
    border-radius: 50%;
    box-shadow: 0 0.5rem 1.5rem 0 var(--color-box-shadow);
}

// TODO: remove once we implement dispatched and completed routes
.depot-marker[data-variant='dispatched'] {
    background-color: var(--color-earth);
}

.depot-marker[data-variant='completed'] {
    background-color: var(--color-mars);
}
