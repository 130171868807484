.react-select-container {
    font-size: var(--text-size-m);

    .react-select__control--is-disabled {
        background-color: var(--color-galaxy-100);
        border: 0.1rem solid var(--color-galaxy-400);
        pointer-events: auto;
        cursor: not-allowed;
    }

    [class$='-control'] {
        border: 0.1rem solid var(--color-galaxy-300);
        border-radius: 0.8rem;
    }

    [class$='-indicatorSeparator'] {
        display: none;
    }
}
