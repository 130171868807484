.managestopcontrol_list-item {
    column-gap: 0.9rem;
    max-width: 20rem;

    & + & {
        margin-top: 0.8rem;
    }

    button {
        background-color: var(--color-comet);
        border-radius: var(--dimension-round);
        width: 2.4rem;
        height: 2.4rem;
    }
}
