.routecard-metric {
    column-gap: 0.2rem;
    line-height: 1.4rem;
    font-size: var(--text-size-xs);
    color: var(--color-galaxy-500);

    .icon + .icon {
        margin-left: -0.2rem;
    }

    &_earth {
        color: var(--color-earth);
    }
    &_mars {
        color: var(--color-mars);
    }
    &_venus {
        color: var(--color-venus);
    }
    &_saturn-500 {
        color: var(--color-saturn-500);
    }
}
