.zonemanagement-zonelist-item-container {
    width: 29rem;
    margin: 0.9rem;
}

.zonemanagement-zonelist-item {
    min-height: 4.7rem;
    gap: 1rem;
    cursor: pointer;

    &.__active,
    &:hover,
    &:focus {
        background-color: var(--color-neptune-200);
        border-radius: var(--border-radius-l);
    }

    .zonelistitem-buttongroup {
        visibility: hidden;
    }

    &:hover .zonelistitem-buttongroup {
        visibility: visible;
        display: flex;
        margin-left: auto;
        gap: 1rem;
    }

    & .listitem-expandicon {
        transform: rotate(-90deg);
    }
}

.assignedVehiclesContainer {
    gap: 0.5rem;
    max-height: 15.5rem;
    overflow: auto;
    margin: 0.9rem 1.8rem 0 1.8rem;
    &.hidden {
        display: none;
    }
}
