.edittimewindow-modal-content {
  font-size: var(--text-size-m);
  color: var(--color-galaxy-800);
  padding: 2.4rem;
}

.edittimewindow-modal-infomessage {
  background-color: var(--color-neptune-100);
  border-radius: var(--border-radius-l);
  font-size: var(--text-size-s);
  padding: 0.6rem 1.8rem;
}

.edittimewindow-modal-errormessage {
  border-radius: 0;
}

.edittimewindow-modal-footer {
  padding: 2.4rem;
}
