.button {
    font-size: 1.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.8rem 0.8rem 0;
    border: 0.1rem solid transparent;
    border-radius: 0.8rem;
    padding: 0.7rem 1.4rem;
    text-transform: capitalize;
    background-color: var(--color-ocean);
    color: var(--color-white);
}

.button:hover {
    cursor: pointer;
}

.button:focus {
    outline: none;
}

.button_disabled,
.button_disabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
}

.button_clear {
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: transparent;
}

button.hidden {
    display: none;
}
