.drawer-notice {
    background-color: var(--color-neptune-100);
    border-radius: var(--border-radius-l);
    padding: 1rem 1.2rem 0.8rem;
}

.drawer-notice_title {
    column-gap: 0.4rem;
}

.drawer-notice_close {
    width: 2rem;
    height: 2rem;
}

.drawer-notice_text {
    margin-top: 0.6rem;
}
