.processindicator {
    position: absolute;
    top: 1rem;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 1rem;
    padding: 1rem;
    z-index: var(--z-index-toast);
    background-color: var(--color-galaxy-800);
    width: 28rem;
    border-radius: var(--border-radius-l);
    opacity: 0.8;
}

.processindicator-item {
    display: flex;
    align-items: center;
}

.processindicator-text {
    color: var(--color-white);
    font-size: 1.4rem;
}

.processindicator-position-right {
    right: 0.8rem;
}

.processindicator-position-center {
    right: 0;
    left: 0;
    margin: auto;
}
