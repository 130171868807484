:root {
    --color-assignment-ASSIGNED: var(--color-earth);
    --color-assignment-IN_PROGRESS: var(--color-ocean);
    --color-assignment-COMPLETED: var(--color-earth);
    --color-assignment-CANCELED: var(--color-saturn);

    --color-status-EARLY: var(--color-earth);
    --color-status-ON_TIME: var(--color-earth);
    --color-status-AT_RISK: var(--color-saturn);
    --color-status-LATE: var(--color-mars);
    --color-status-CANCELED: var(--color-galaxy-300);

    --color-status-EARLY-completed: rgba(65, 191, 172, 0.4); // earth 50%
    --color-status-ON_TIME-completed: rgba(65, 191, 172, 0.4); // earth 40%
    --color-status-AT_RISK-completed: rgba(255, 199, 117, 0.4); // saturn 40%
    --color-status-LATE-completed: rgba(255, 86, 112, 0.4); // mars 40%

    --color-driver-offline: var(--color-galaxy-500);
}

.routecard-statusbar {
    width: 100%;

    .routecard-summary {
        margin-bottom: 0.8rem;
    }
}

.gradient {
    height: 0.8rem;
    background-color: var(--color-galaxy-200);
    border-radius: var(--border-radius-l);

    &_pointer {
        width: 1.2rem;
        height: 1.2rem;
        top: -0.2rem;
        left: 0;
        background-color: var(--color-galaxy-400);
        border: 0.2rem solid var(--color-comet);
        border-radius: var(--border-radius-l);
        transform: translate(-50%, 0);
        box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);

        &[data-offline] {
            border-color: var(--color-driver-offline);

            &::after {
                content: '';
                height: 0.2rem;
                width: 0.8rem;
                border-radius: var(--border-radius-s);
                background-color: var(--color-comet);
            }
        }
    }

    .pointer_depot,
    .pointer_undefined {
        display: none !important;
    }
}
