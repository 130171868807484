@import 'src/ui/mixins/mapmarker';

.clustermarker {
    @include mapmarker;
}

.clustermarker-circle {
    @include mapmarker-circle($circle-size: 5.4rem);
    flex-direction: column;
}

.clustermarker-main {
    font-size: 1.4rem;
    font-weight: bold;
}
