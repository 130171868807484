.photomodal {
  margin: 1.6rem;
  max-height: 42rem;
  overflow: auto;
}

.photomodal-inventoryitem:hover .photomodal-button{
    display: flex;
}

.photomodal-inventoryphoto {
  background-color: var(--color-comet);
  border: 0.1rem solid var(--color-galaxy-300);
  border-radius: var(--border-radius-l);
  width: 14rem;
  height: 14rem;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
}

.photomodal-image {
  max-width: 100%;
}

.photomodal-button {
  display: none;
  background-color: var(--color-comet);
  box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;

  &:hover {
    background-color: var(--color-galaxy-50);
  }
}
