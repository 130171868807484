.errormessage {
    padding: 1.2rem 1.8rem;
    border-radius: var(--border-radius-l);
    background-color: var(--color-mars-100);
    font-weight: 400;
}

.inlinemessage {
    padding: 1.2rem 0;
    color: var(--color-mars-500);
}
