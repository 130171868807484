@mixin button-variant(
    $button-color: var(--color-comet),
    $button-background-color: var(--color-ocean),
    $button-border-color: var(--color-ocean),
    $button-border-radius: var(--border-radius-l),
    $button-hover-color: $button-color,
    $button-hover-background-color: var(--color-ocean-500),
    $button-hover-border-color: var(--color-ocean-500),
    $button-disabled-color: var(--color-galaxy-400),
    $button-disabled-background-color: var(--color-galaxy-200),
    $button-disabled-border-color: var(--color-galaxy-200)
) {
    color: $button-color;
    background-color: $button-background-color;
    border: 0.1rem solid $button-border-color;
    border-radius: $button-border-radius;

    &:hover {
        color: $button-hover-color;
        background-color: $button-hover-background-color;
        border-color: $button-hover-border-color;
    }

    &:disabled {
        &,
        &:hover {
            color: $button-disabled-color;
            background-color: $button-disabled-background-color;
            border-color: $button-disabled-border-color;
        }
    }
}

@mixin button-variant-primary($args...) {
    @include button-variant($args...);
}

@mixin button-variant-secondary(
    $button-color: var(--color-meteor),
    $button-background-color: var(--color-comet),
    $button-border-color: var(--color-galaxy-300),
    $button-hover-color: $button-color,
    $button-hover-background-color: var(--color-neptune-100),
    $button-hover-border-color: var(--color-ocean)
) {
    @include button-variant(
        $button-color: $button-color,
        $button-background-color: $button-background-color,
        $button-border-color: $button-border-color,
        $button-hover-color: $button-hover-color,
        $button-hover-background-color: $button-hover-background-color,
        $button-hover-border-color: $button-hover-border-color,
        $button-disabled-background-color: var(--color-comet)
    );
}

@mixin button-variant-link($button-color: var(--color-meteor)) {
    @include button-variant(
        $button-color: $button-color,
        $button-background-color: transparent,
        $button-border-color: transparent,
        $button-hover-background-color: transparent,
        $button-hover-border-color: transparent,
        $button-disabled-background-color: transparent,
        $button-disabled-border-color: transparent
    );

    &:hover {
        text-decoration: underline;
    }

    &:disabled {
        &,
        &:hover {
            text-decoration: none;
        }
    }
}

$button-padding-default: initial;
$button-padding-l: 1.3rem 1.6rem;
$button-padding-m: 0.5rem 1.6rem;
$button-padding-s: $button-padding-default;

$button-height-default: auto;
$button-height-l: 4.8rem;
$button-height-m: 3.2rem;
$button-height-s: $button-height-default;

$button-font-size-default: inherit;
$button-font-size-l: var(--text-size-m);
$button-font-size-m: var(--text-size-s);
$button-font-size-s: $button-font-size-default;

$button-line-height-default: inherit;
$button-line-height-l: 2.4rem;
$button-line-height-m: 2rem;
$button-line-height-s: $button-line-height-default;

@mixin button-size($size: default) {
    @if $size == l {
        padding: $button-padding-l;
        height: $button-height-l;
        font-size: $button-font-size-l;
        line-height: $button-line-height-l;
    } @else if $size == m {
        padding: $button-padding-m;
        height: $button-height-m;
        font-size: $button-font-size-m;
        line-height: $button-line-height-m;
    } @else if $size == s {
        padding: $button-padding-s;
        height: $button-height-s;
        font-size: $button-font-size-s;
        line-height: $button-line-height-s;
    } @else {
        padding: $button-padding-default;
        height: $button-height-default;
        font-size: $button-font-size-default;
        line-height: $button-line-height-default;
    }
}
