@import 'src/ui/mixins/mapmarker';

.livestopmarker {
    @include mapmarker;

    position: relative;
}

.livestopmarker-circle {
    @include mapmarker-circle($circle-parent-name: livestopmarker);

    font-size: var(--text-size-xxs);
    font-weight: 500;
    color: var(--color-galaxy-500);
    background-color: var(--color-comet);
}

.livestopmarker-label {
    @include mapmarker-label($circle-parent-name: livestopmarker);
}

.livestopmarker-canceled {
    width: 2.5rem;
    height: 2.5rem;
}

.livestopmarker_atrisk {
    color: var(--color-galaxy-800);
    background-color: var(--color-saturn);
}

.livestopmarker_late {
    color: var(--color-comet);
    background-color: var(--color-mars);
}

.livestopmarker_completed {
    color: var(--color-galaxy-500);
    background-color: var(--color-comet);
}

.livestopmarker-priority {
    @include mapmarker-star();
}

.livestopmarker-priority_text {
    @include mapmarker-star-number($text-x-translate: -50%);
    position: absolute;
}

.livestopmarker-icon {
    position: absolute;
    top: 1.2rem;
    left: 1.4rem;

    &.icon-priority {
        top: 2rem;
        left: 2rem;
    }

    &.icon-exception {
        width: 1.5rem;
        height: 1.5rem;
        top: 1.8rem;
        left: 1.6rem;
    }

    &.icon-default {
        width: 1.5rem;    }
    }

.livestopmarker_selected {
    .livestopmarker_icon-group {
        border-radius: var(--dimension-round);
        border: 0.2rem solid var(--color-black);
        background-color: rgba(255, 255, 255, 0.8); // comet, 80% opacity
        padding: 0.5rem;
    }
}