.mapsettingssection_title {
    font-size: 1.2rem;
    padding: 0 1.6rem;
    color: var(--color-galaxy-500);
    text-transform: uppercase;
}

.mapsettingssection_item {
    font-size: 1.4rem;
    margin-left: 1.1rem;
    color: var(--color-galaxy-500);

    &[data-enabled] {
        color: var(--color-galaxy-800);
    }
}
