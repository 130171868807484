$display-options: block, inline-block, flex, inline-flex, none, grid;

@each $item in $display-options {
    ._d-#{$item} {
        @if $item == none {
            display: $item !important;
        } @else {
            display: $item;
        }
    }
}
