.suggesteddrivercard {
    background-color: var(--color-comet);
}

.suggesteddrivercard-header {
    & > *:last-child {
        margin-left: 0.8rem;
    }
}

.suggesteddrivercard-header_text {
    margin-left: 0.8rem;

    & > div {
        width: 14rem;
        text-align: left;
    }
}

.suggesteddrivercard-best {
    font-size: var(--text-size-xs);
    line-height: 1;
    color: var(--color-meteor);
}

.suggesteddrivercard-metrics {
    & > * {
        &:first-child {
            margin-top: 0;
        }
        margin: 1.2rem 0;
    }
}

.suggesteddrivercard-dispatch_text {
    padding-left: 0.8rem;
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
}

.suggesteddrivermetric {
    display: grid;
    grid-template-columns: 6.33rem 6.33rem 6.33rem;
}

.suggesteddrivermetric-label {
    color: var(--color-galaxy-500);
    padding-left: 0.4rem;
}

.suggestdrivermetric-value {
    color: var(--color-galaxy-800);
    &[data-highlight="true"] {
        color: var(--color-meteor);
    }
}

.suggesteddrivermetric-delays {
    border-radius: var(--border-radius-l);
    padding: 0.4rem 0.6rem;
}