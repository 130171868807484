.dropdown-input-wrapper {
    display: flex;
    flex-direction: column;
}

.dropdown-input-title {
    margin-bottom: 0.8rem;
}

.dropdown {
    position: relative;
}

.dropdown-input {
    border: 0.1rem solid var(--color-galaxy-300);
    border-radius: 0.8rem;
    font-size: 1.6rem;
    padding: 1.2rem 4rem 1.2rem 1.6rem;
    cursor: pointer;
    width: 100%;
}

.dropdown-input:focus {
    outline: none;
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
}

.dropdown-input::placeholder {
    color: var(--color-galaxy-400);
}

.dropdown-arrow {
    position: relative;
    left: 100%;
    margin-left: -4rem;
    bottom: 3.8rem;
}

.dropdown-arrow--rotate {
    transform: rotate(180deg);
}

.dropdown-selections {
    position: absolute;
    right: 0;
    width: 100%;
    margin-top: -3rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.6rem var(--color-box-shadow);
    background-color: var(--color-comet);
    display: flex;
    flex-direction: column;
    max-height: 30rem;
    overflow: auto;
}

.dropdown-selections-items {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 1rem 1.6rem;
}

.dropdown-selections-items:hover {
    background-color: var(--color-galaxy-50);
}
