/**
 * Note: we need to separate out the border styles because applying them universally to td or th
 * elements leads to inconsitent border width rendering between cells
 */
.input-table {
    --input-table-border: 0.1rem solid var(--color-galaxy-300);

    background-color: white;
    border-spacing: 0;
    width: 100%;

    & th {
        background-color: var(--color-galaxy-50);
        padding: 1rem 0.8rem;
    }

    & th, & td {
        /* Every cell gets a bottom and right border */
        border-right: var(--input-table-border);
        border-bottom: var(--input-table-border);

        /* Top and left border is transparent and is visible due to the adjacent cell borders */
        border-top: 0.1rem solid transparent;
        border-left: 0.1rem solid transparent;
    }

    & td:hover, & td.focus {
        background-color: var(--color-neptune-100);

        border: 0.1rem solid var(--color-ocean) !important;
    }

    & td.error {
        background-color: var(--color-mars-100);
        border: 0.1rem solid var(--color-mars-600) !important;
    }

    & thead tr:first-child th {
        /* First row of thead sets border top */
        border-top: var(--input-table-border);
    }

    & th:first-child, & td:first-child {

        /* All left-most cells set border-left */
        border-left: var(--input-table-border);
    }

    & .editableCell-input {
        width: 100%;
        height: 100%;
        padding: 1rem 0.8rem;
        background: transparent;
        border: none;
        outline: none;
    }
}
