.map-type-control {
  height: 6.3rem;
  width: 7rem;
  outline: none;
  border: 0.1rem solid var(--color-comet);
  border-radius: var(--border-radius-l);
  color: var(--color-comet);
  align-items: end;
  font-size: var(--text-size-xs);


  &:hover {
    cursor: pointer;
  }

  &_satelliteview {
    background-image: url('~/ui/assets/images/map-satellite-view.jpg');

  }

  &_defaultview {
    background-image: url('~/ui/assets/images/map-default-view.jpg');
    color: var(--color-galaxy-800);
  }

}
