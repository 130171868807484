.marker-popup {
    position: absolute;
    bottom: 2rem;
    left: 0;
    transform: translate(-50%, 0);
    width: 20rem;
    padding: 1.2rem;
    background-color: var(--color-comet-alpha-70);
    border-radius: var(--border-radius-l);
    backdrop-filter: blur(2.4rem);
    row-gap: 0.8rem;
}

/*fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .marker-popup {
        background-color: rgb(248, 247, 245, 1);
    }
}

.marker-popup:hover {
    cursor: default;
}

.marker-popup-header {
    font-size: var(--text-size-s);
    color: var(--color-galaxy-800);
    text-transform: capitalize;
    row-gap: 0.8rem;
}

.marker-popup-body {
    row-gap: 0.8rem;

    .detail {
        margin: 0;
        font-size: var(--text-size-xs);
        color: var(--color-galaxy-500);
    }

    div.icon_union {
        position: relative;
        left: 0.4rem;
        top: 0.4rem;
    }

    div.icon {
        flex-shrink: 0;
    }

    button {
        height: 1.5rem;
        font-size: var(--text-size-xs);
    }
}
