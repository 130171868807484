.datepickerheader {
    padding: 1rem 1.2rem;
}

.datepickerheader-title {
    width: 12rem;
}

.arrowbutton-icon-90 {
    transform: rotate(90deg);
}

.arrowbutton-icon-270 {
    transform: rotate(270deg);
}
