.units {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.2rem;
}

.units_gapless { gap: 0; }

.units-1 { grid-column-start: span 1; }
.units-2 { grid-column-start: span 2; }
.units-3 { grid-column-start: span 3; }
.units-4 { grid-column-start: span 4; }
.units-5 { grid-column-start: span 5; }
.units-6 { grid-column-start: span 6; }
.units-7 { grid-column-start: span 7; }
.units-8 { grid-column-start: span 8; }
.units-9 { grid-column-start: span 9; }
.units-10 { grid-column-start: span 10; }
.units-11 { grid-column-start: span 11; }
.units-12 { grid-column-start: span 12; }
