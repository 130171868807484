.ondemanddispatchmapcontrol_marker {
    height: 4rem;
    width: 4rem;
    border-radius: var(--dimension-round);
    border: 1px solid var(--color-comet);

    &:not([disabled]):hover {
        border-color: var(--color-galaxy-500);
    }
}
